<template>
  <div class="container h-100">
    <div class="row align-items-center h-100">
      <div class="col-6 mx-auto">
        <div class="jumbotron text-center">
          <span style="font-size:200%;">{{error.message}}</span><br/>
          <a href="/">Go back to homepage</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error',
  props: ['error']
}
</script>

<style scoped>

</style>
